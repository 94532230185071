import { PayloadAction, createSlice, current } from '@reduxjs/toolkit'
import { FeedbackEvent } from '@typings/feedbackEvent.types'
import { FeedbackState } from '@typings/store/feedbackState.types'

const initialState: FeedbackState = {
  ongoingFeedbackEvent: null,
  completedFeedbackEvents: [],
}

export const feedbackSlice = createSlice({
  name: 'feedbackState',
  initialState,
  reducers: {
    triggerFeedbackEvent: (store, action: PayloadAction<FeedbackEvent>) => {
      const feedbackEvent = action.payload
      const storedFeedbackEvents = current(store.completedFeedbackEvents)

      if (!checkIfFeedbackHasBeenGiven(storedFeedbackEvents, feedbackEvent)) {
        store.ongoingFeedbackEvent = feedbackEvent
      }
    },
    concludeFeedbackEvent: (store, action: PayloadAction<FeedbackEvent>) => {
      const feedbackEvent = action.payload
      const storedFeedbackEvents = current(store.completedFeedbackEvents)

      if (!checkIfFeedbackHasBeenGiven(storedFeedbackEvents, feedbackEvent)) {
        const updatedCompletedFedbackEvents = [...storedFeedbackEvents, feedbackEvent]
        store.completedFeedbackEvents = updatedCompletedFedbackEvents
        localStorage.setItem('completed-feedback', JSON.stringify(updatedCompletedFedbackEvents))
      }

      store.ongoingFeedbackEvent = null
    },
  },
})

const checkIfFeedbackHasBeenGiven = (completedFeedback: FeedbackEvent[], feedbackEvent: FeedbackEvent) => {
  return completedFeedback.find(
    (completedFeedback) =>
      completedFeedback.feedbackType === feedbackEvent.feedbackType &&
      completedFeedback.raNumber === feedbackEvent.raNumber
  )
}

export const { triggerFeedbackEvent, concludeFeedbackEvent } = feedbackSlice.actions
export const feedbackStateReducer = feedbackSlice.reducer
