import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import {
  loadBrandFromLocalStorage,
  loadFeedbackStateFromLocalStorage,
  loadLocaleFromLocalStorage,
  loadUiStateFromLocalStorage,
  setLocalStorageMiddleware,
} from './localStorageMiddleware'
import { carDamagesReducer } from './slices/carDamagesSlice'
import { contractReducer } from './slices/contractSlice'
import { creditCardReducer } from './slices/creditCardSlice'
import { eltonConsentReducer } from './slices/eltonConsentSlice'
import { feedbackStateReducer } from './slices/feedbackSlice'
import { insurancesReducer } from './slices/insurancesSlice'
import { invoicesReducer } from './slices/invoicesSlice'
import { localeReducer } from './slices/localeSlice'
import { uiStateReducer } from './slices/uiStateSlice'
import { userImagesReducer } from './slices/userImagesSlice'

const preloadedState = {
  locale: loadLocaleFromLocalStorage(),
  contract: loadBrandFromLocalStorage(),
  uiState: loadUiStateFromLocalStorage(),
  feedback: loadFeedbackStateFromLocalStorage(),
}

const store = configureStore({
  reducer: {
    contract: contractReducer,
    carDamages: carDamagesReducer,
    eltonConsent: eltonConsentReducer,
    insurances: insurancesReducer,
    uiState: uiStateReducer,
    userImages: userImagesReducer,
    locale: localeReducer,
    invoices: invoicesReducer,
    creditCard: creditCardReducer,
    feedback: feedbackStateReducer,
  },
  preloadedState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(setLocalStorageMiddleware),
})

export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
export type RootState = ReturnType<typeof store.getState>

export default store
