/* eslint-disable no-case-declarations */
import { Brand } from '@hertz/odinui-web'
import type { Middleware, PayloadAction } from '@reduxjs/toolkit'
import { Contract } from '@typings/api/contract.types'
import { FeedbackEvent } from '@typings/feedbackEvent.types'
import { Locale } from '@typings/localization/locale.types'
import { ContractState } from '@typings/store/contractState.types'
import { FeedbackState } from '@typings/store/feedbackState.types'
import { UiState } from '@typings/store/uiState.types'
import { mapLocaleToString, mapStringToLocale } from '@utils/localeHelper'
import { setOneTrustLanguage } from '@utils/setOneTrustLanguage'

export const setLocalStorageMiddleware: Middleware = () => (next) => (action: PayloadAction<unknown, string>) => {
  const result = next(action)

  switch (action.type) {
    case 'contract/get/fulfilled':
      const contract = action.payload as Contract
      if (!contract.agreement?.voidedRaIndicator) {
        const brand = contract.agreement?.brand?.toLowerCase() || 'hertz'
        localStorage.setItem('brand', brand)
      }
      break
    case 'uiState/closeOnboardingBanner':
      localStorage.setItem('onboarding-banner-closed', 'true')
      break
    case 'uiState/closeEndRentalBanner':
      localStorage.setItem('endRental-banner-closed', 'true')
      break
    case 'uiState/closeInfoBanner':
      localStorage.setItem('info-banner-closed', 'true')
      break
    case 'locale/changeLocale':
      const locale = action.payload as Locale
      if (locale.country !== 'us') {
        localStorage.setItem('locale', mapLocaleToString(locale))
      }
      break
    default:
      break
  }

  return result
}

export const loadBrandFromLocalStorage = (): ContractState => {
  let brand: Brand = 'hertz'

  if (typeof window !== 'undefined') {
    const storedBrand = localStorage.getItem('brand')
    if (storedBrand) {
      brand = storedBrand.toLowerCase() as Brand
    }
  }

  return {
    contractLoadingState: 'idle',
    brand: brand,
  }
}

export const loadLocaleFromLocalStorage = () => {
  const storedLocale = (typeof window !== 'undefined' && localStorage.getItem('locale')) || null
  if (storedLocale) {
    const locale = mapStringToLocale(storedLocale)
    setOneTrustLanguage(locale)
    return {
      locale: locale,
    }
  }
}

const getUiStateFromLocalStorage = (key: string) => {
  const bannerState = typeof window !== 'undefined' ? localStorage.getItem(`${key}-banner-closed`) : 'false'
  return bannerState === 'true'
}

export const loadUiStateFromLocalStorage = (): UiState => {
  return {
    userClosedOnboardingBanner: getUiStateFromLocalStorage('onboarding'),
    userClosedEndRentalBanner: getUiStateFromLocalStorage('endRental'),
    userClosedInfoBanner: getUiStateFromLocalStorage('info'),
    mobileMenuOpen: false,
  }
}

const getFeedbackFromLocalStorage = () => {
  if (typeof window !== 'undefined') {
    const completedFeedback = localStorage.getItem('completed-feedback')

    if (completedFeedback) {
      return JSON.parse(completedFeedback) as FeedbackEvent[]
    } else {
      localStorage.removeItem('completed-feedback')
    }
  }

  return []
}

export const loadFeedbackStateFromLocalStorage = (): FeedbackState => {
  return {
    ongoingFeedbackEvent: null,
    completedFeedbackEvents: getFeedbackFromLocalStorage(),
  }
}
