import { ThemeProvider } from '@hertz/storybook'
import * as React from 'react'
import { Provider } from 'react-redux'

import './src/style/global.css'
import store from './src/store/store'

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider brand="Hertz">
      <Provider store={store}>{element}</Provider>
    </ThemeProvider>
  )
}
